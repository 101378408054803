"use client";

import * as Sentry from "@sentry/nextjs";
import NextError from "next/error";
import {useEffect} from "react";
import {mantineTheme} from "@/components/theme";
import {MantineProvider} from "@mantine/core";
import {Shell} from "@/components/shell/Shell";

export default function GlobalError({
                                        error,
                                    }: {
    error: Error & { digest?: string };
}) {
    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        <MantineProvider defaultColorScheme="dark" theme={mantineTheme}>
            <Shell noHeader>
                {/*This is the default Next.js error component but it doesn't allow omitting the statusCode property yet.*/}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <NextError statusCode={undefined}/>
            </Shell>
        </MantineProvider>
    );
}
